// internal variables for unique ID
const idInfix = Math.random().toString(16).substring(2);
let idIncrementor = 0;

export default {
  install: (app) => {
    /**
     * Tests a given value and determines, if it is a genuine javascript object.
     *
     * @param {*} obj
     *   The value to test.
     * @returns {boolean}
     *   True, if the given value is a javascript object
     */
    app.config.globalProperties.$isObject = (obj) => {
      return Object.prototype.toString.call(obj) === "[object Object]";
    };

    /**
     * Cleans a given object from all empty properties.
     *
     * As "empty" are considered:
     * - undefined
     * - null
     * - ""
     * - []
     * - {}
     *
     * As "not empty" are considered
     * - 0
     * - false
     * - " "
     */
    app.config.globalProperties.$cleanObject = function (obj, filterProperties = false) {
      const cleanObj = {};

      for (const propName in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, propName)) {
          continue;
        }
        if (filterProperties) {
          if (
            (typeof filterProperties === "string" && propName === filterProperties) ||
            (Array.isArray(filterProperties) && filterProperties.indexOf(propName) > -1) ||
            (filterProperties instanceof RegExp && filterProperties.test(propName))
          ) {
            continue;
          }
        }
        if (obj[propName] !== undefined && obj[propName] !== null) {
          if (typeof obj[propName] !== "object" && !Array.isArray(obj[propName])) {
            if (obj[propName] !== "") {
              cleanObj[propName] = obj[propName];
            }
          } else if (typeof obj[propName] === "object" && !Array.isArray(obj[propName])) {
            const cleanSubObj = this.$cleanObject(obj[propName], filterProperties);

            if (Object.keys(cleanSubObj).length) {
              cleanObj[propName] = cleanSubObj;
            }
          } else if (Array.isArray(obj[propName]) && obj[propName].length) {
            cleanObj[propName] = JSON.parse(JSON.stringify(obj[propName]));
          }
        }
      }
      return cleanObj;
    };

    /**
     * function to create a unique id. Can be used with prefix and postfix.
     * @param prefix
     * @param suffix
     * @returns {string}
     */
    app.config.globalProperties.$getUniqueId = (prefix = "", suffix = "") => {
      return `${prefix}${idInfix}${idIncrementor++}${suffix}`;
    };

    /**
     * Clones a primitive or non-primitive value (replacement of underscore clone)
     * @param {*} val
     *   The value to clone.
     * @returns {value}
     *   The cloned value.
     */
    app.config.globalProperties.$deepClone = (val) => {
      if (typeof val === "function") return val;

      return JSON.parse(JSON.stringify(val));
    };

    /**
     * Function to debounce/wait to call a function
     * added as global; as we may use a library function in future
     * @param {function} fn - a callback to debounce
     * @param {number} wait - delay in ms
     * */
    app.config.globalProperties.$debounce = (fn, wait) => {
      let timer;

      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        timer = setTimeout(() => {
          //this is passed so that the current context is considered
          // call the function if time expires
          fn.apply(this, args);
        }, wait);
      };
    };
  },
};
