import moment from "moment";

/**
 * function to filter objects
 * @param {object} source - Object that should be filtered
 * @param {function} predicate - function for filter conditions
 * @return {object} - filtered object
 */
export function filter(source, predicate) {
  return Object.keys(source)
    .filter((key) => predicate(source[key], key))
    .reduce((res, key) => Object.assign(res, { [key]: source[key] }), {});
}

/**
 * function to sort arrays by category in asc/desc order
 * @param {object[]} elements - array of elements to sort
 * @param {string} category - name of the category to sort by
 * @param {boolean} asc - true if asc false is desc
 * @param {string} [type] - pass type for special types like 'date'
 * @param {string} [propertyName] - optional to sort array of objects based on a property value
 * @return {object[]} - array of sorted elements
 */
export function sort(elements, category, asc, type, propertyName) {
  // first letter to lower case
  const categoryName = (category && category[0].toLowerCase() + category.slice(1)) || category;

  if (elements.length === 1) {
    return elements;
  }

  if (type === "date") {
    return elements.sort((a, b) => {
      if (asc) {
        return moment(a[categoryName], "DD.MM.YYYY, HH:mm").isSameOrBefore(
          moment(b[categoryName], "DD.MM.YYYY, HH:mm"),
        )
          ? -1
          : 1;
      } else {
        return moment(a[categoryName], "DD.MM.YYYY, HH:mm").isAfter(
          moment(b[categoryName], "DD.MM.YYYY, HH:mm"),
        )
          ? -1
          : 1;
      }
    });
  }

  return elements.sort((a, b) => {
    let nameA, nameB;
    const isObject = type === "object";

    if (asc) {
      nameA =
        isObject && propertyName in a[categoryName]
          ? a[categoryName][propertyName] ?? undefined
          : a[categoryName] ?? undefined;

      nameB =
        isObject && propertyName in b[categoryName]
          ? b[categoryName][propertyName] ?? undefined
          : b[categoryName] ?? undefined;
    } else {
      nameA =
        isObject && propertyName in b[categoryName]
          ? b[categoryName][propertyName] ?? undefined
          : b[categoryName] ?? undefined;
      nameB =
        isObject && propertyName in a[categoryName]
          ? a[categoryName][propertyName] ?? undefined
          : a[categoryName] ?? undefined;
    }

    if (nameA === undefined || nameB === undefined) {
      return nameA === undefined ? 1 : -1;
    }

    nameA = nameA.toLowerCase();
    nameB = nameB.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}
