import type { KeycloakInitOptions } from "keycloak-js";
import Keycloak from "keycloak-js";

interface logoutCallbackType {
  (redirect: boolean): void;
}

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "login-required",
  checkLoginIframe: false,
  enableLogging: false,
};

/* For "keycloak-js" adapter documentation see:
 * https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
 * */
const keycloak = new Keycloak("/keycloak.json");

/**
 * Initializes Keycloak
 */
async function init(logoutCallback: logoutCallbackType) {
  try {
    await keycloak.init(keycloakInitOptions);

    keycloak.onAuthRefreshError = () => logoutCallback(false);
    keycloak.onAuthLogout = () => logoutCallback(true);
    keycloak.onTokenExpired = refreshToken;

    return keycloak;
  } catch (error) {
    console.error("Keycloak init failed\n", error);
  }
}

/**
 * Logout user
 */
async function logout(): Promise<void> {
  await keycloak.logout().catch((error) => {
    console.error("Keycloak logout failed\n", error);
  });
}

/**
 * Logout user with redirect
 */
async function logoutRedirect(): Promise<void> {
  const baseURL = window.location.origin;

  const redirectUri = `${baseURL}/logout`;

  const logoutOptions = { redirectUri: redirectUri };

  await keycloak.logout(logoutOptions).catch((error) => {
    console.error("Keycloak logout failed\n", error);
  });
}

/**
 * Refreshes token
 */
async function refreshToken(tokenInterval = 300) {
  try {
    await keycloak.updateToken(tokenInterval);
    return keycloak;
  } catch (error) {
    console.error("Keycloak failed to refresh token\n", error);
  }
}

const KeycloakService = {
  CallInit: init,
  CallLogout: logout,
  CallLogoutRedirect: logoutRedirect,
  CallTokenRefresh: refreshToken,
};

export default KeycloakService;
