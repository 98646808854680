import {
  GetAllCodelistenResolver,
  GetAllResolver,
  GetAllVerfahrenResolver,
  GetBlacklistResolver,
  GetNutzerVerfahrenResolver,
} from "@/router/services.js";

export const startRoutes = {
  path: "/",
  component: () => import("@/views/VStart.vue"),
  name: "VStart",
  props: true,
  meta: {
    resolve: {
      "/verfahren": GetAllVerfahrenResolver,
      "/nutzer/verfahren": GetNutzerVerfahrenResolver,
      "/konfigurationen/codelisten": GetAllCodelistenResolver,
      "/konfigurationen/blacklist": GetBlacklistResolver,
    },
  },
  children: [
    {
      path: "/",
      component: () => import("@/views/VStartSubscribedProceedings.vue"),
      name: "VStartSubscribedProceedings",
      meta: {
        title: ["Abonnierte Verfahren"],
        label: "Abonnierte Verfahren",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
        bodyClass: "has-fixed-footer",
      },
    },
    {
      path: "/suche",
      component: () => import("@/views/VStartSearch.vue"),
      name: "VStartSearch",
      meta: {
        title: ["Verfahrenssuche"],
        label: "Verfahrenssuche",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetAllResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "/uebersicht",
      component: () => import("@/views/start/VStartProcessStepView.vue"),
      name: "VStartProcessStepView",
      meta: {
        title: ["Verfahrensschritte"],
        label: "Verfahrensschritte",
        requiredPermissions: ["ADMIN", "VERFAHRENSSTEUERUNG_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "/zeitstrahl",
      component: () => import("@/views/start/VStartTimeline.vue"),
      name: "VStartTimeline",
      meta: {
        title: ["Zeitstrahl"],
        label: "Zeitstrahl",
        requiredPermissions: ["ADMIN", "VERFAHRENSSTEUERUNG_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "/createplanverfahren",
      component: () => import("@/views/VStartCreatePlanverfahren.vue"),
      name: "VStartCreatePlanverfahren",
      meta: {
        title: ["Verfahren anlegen"],
        label: "Verfahren anlegen",
        requiredPermissions: ["ADMIN", "VERFAHREN_WRITE", "EIGENE_VERFAHREN_CREATE"],
        resolve: {
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
        bodyClass: "has-fixed-footer",
      },
    },
  ],
};
