import router from "@/router/index.js";
import { useFundamentalStore } from "@/stores/fundamental.ts";
import { useProceedingsStore } from "@/stores/proceedings.ts";

export function setPageTitle(routeData) {
  const proceedingsStore = useProceedingsStore();
  const baseTitle = "DiPlanung Cockpit Basis";
  const separator = " - ";
  const subSeparator = " - ";
  const proceedingPlaceholder = "{proceedingName}";
  let currentTitle = baseTitle;

  if (routeData.meta && routeData.meta.title) {
    if (typeof routeData.meta.title === "string") {
      currentTitle = routeData.meta.title + separator + baseTitle;
    } else if (Array.isArray(routeData.meta.title)) {
      currentTitle = routeData.meta.title.join(subSeparator) + separator + baseTitle;
    }
  }

  // Set proceeding name
  if (currentTitle.includes(proceedingPlaceholder)) {
    if (routeData.params.planID && proceedingsStore.proceedingContainer[routeData.params.planID]) {
      const proceedingData = proceedingsStore.proceedingContainer[routeData.params.planID];

      const proceedingName = proceedingData.workingCopy?.planname;
      const proceedingArbeitstitel = proceedingData.workingCopy?.arbeitstitel;
      const displayName = proceedingName ? proceedingName : proceedingArbeitstitel;

      currentTitle = currentTitle.replace(
        proceedingPlaceholder,
        proceedingData.subscribed ? displayName : "Nicht abonniert: " + displayName,
      );
    } else {
      currentTitle = baseTitle;
    }
  }

  document.title = currentTitle;
}

export async function NutzerResolver() {
  // Liefert die Nutzerinformationen
  // /nutzer
  // getNutzer
  const fundamentalStore = useFundamentalStore();

  await fundamentalStore.loadUserSettings();
}

export function GetAllVerfahrenResolver() {
  // Liefert die Metadaten zu allen Verfahren, die ich sehen darf (abonniert habe)
  // get /verfahren
  // getAllVerfahren
  const proceedingsStore = useProceedingsStore();

  proceedingsStore.loadSubscribedProceedings();
}

export async function GetAllResolver() {
  // Liefert (alle) VerfahrenKonfigurationen
  // get /konfigurationen/verfahren
  // getAll
  const fundamentalStore = useFundamentalStore();

  await fundamentalStore.loadProceedingStructures();
}

export async function GetAllCodelistenResolver() {
  // Liefert alle Codelisten aus
  // get /konfigurationen/codelisten
  // getAllCodelisten
  const fundamentalStore = useFundamentalStore();

  await fundamentalStore.loadConfigurationCodelists();
}

export function GetNutzerVerfahrenResolver() {
  // Verfahren IDs der abonnierten Verfahren
  // get /nutzer/verfahren
  // getNutzerVerfahren
  const proceedingsStore = useProceedingsStore();

  proceedingsStore.loadSubscribedProceedingIDs();
}

export async function GetBlacklistResolver() {
  // Liefert die Blackliste aus
  // get /konfigurationen/blacklist
  // getBlacklist
  const fundamentalStore = useFundamentalStore();

  await fundamentalStore.loadDateBlacklist();
}

/**
 * Function to check if user has the permission to route
 * @param {Array} requiredPermissions
 * */
export function userHasPermissions(requiredPermissions) {
  const fundamentalStore = useFundamentalStore();

  if (fundamentalStore.userSettings) {
    const userRights = fundamentalStore.userSettings?.rechte;

    return requiredPermissions.some((permission) => userRights.includes(permission));
  }

  return false;
}

/**
 * @returns {string|undefined}
 */
export function getPlanID() {
  return router.currentRoute.value.params.planID !== undefined
    ? router.currentRoute.value.params.planID.toString()
    : undefined;
}
