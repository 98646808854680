/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.135.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CodeVerfahrensschritt } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { VerfahrenVorlageRest } from '../models';
// @ts-ignore
import { VorlagePruefungRest } from '../models';
// @ts-ignore
import { VorlageRest } from '../models';
/**
 * VorlageResourceApi - axios parameter creator
 * @export
 */
export const VorlageResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Eine neue Vorlage in die K1 hochladen.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine neue Vorlage in die K1 hochladen.
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {string} beschreibung 
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, beschreibung: string, skipValidation?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vorlage' is not null or undefined
            assertParamExists('create', 'vorlage', vorlage)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('create', 'codeMusterdokument', codeMusterdokument)
            // verify required parameter 'codeVerfahrensunterlagetyp' is not null or undefined
            assertParamExists('create', 'codeVerfahrensunterlagetyp', codeVerfahrensunterlagetyp)
            // verify required parameter 'beschreibung' is not null or undefined
            assertParamExists('create', 'beschreibung', beschreibung)
            const localVarPath = `/vorlagen/{codeMusterdokument}/create`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVerfahrensunterlagetyp !== undefined) {
                localVarQueryParameter['codeVerfahrensunterlagetyp'] = codeVerfahrensunterlagetyp;
            }

            if (beschreibung !== undefined) {
                localVarQueryParameter['beschreibung'] = beschreibung;
            }

            if (skipValidation !== undefined) {
                localVarQueryParameter['skipValidation'] = skipValidation;
            }


            if (vorlage !== undefined) { 
                localVarFormParams.append('vorlage', vorlage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Mustervorlage (Metadaten inkl. Versionen und Binaries)</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Mustervorlage (Metadaten inkl.
         * @param {string} codeMusterdokument Musterdokumentcode zu dem die Vorlage komplett gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVorlage: async (codeMusterdokument: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('deleteVorlage', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/vorlagen/{codeMusterdokument}`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        determineNextFreeCodeMusterdokument: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vorlagen/nextFreeCodeMusterdokument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Download einer Vorlage als Binary  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene records verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Download einer Vorlage als Binary  
         * @param {string} codeMusterdokument dokument.getcodeMusterdokument
         * @param {string} [version] version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageDatei: async (codeMusterdokument: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('getVorlageDatei', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/vorlagen/{codeMusterdokument}/datei`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einer Vorlage (Dokument).</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Liefert die Metadaten zu einer Vorlage (Dokument).
         * @param {string} codeMusterdokument codeMusterdokument des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageDetail: async (codeMusterdokument: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('getVorlageDetail', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/vorlagen/{codeMusterdokument}`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen. Dieser Endpunkt ist für den Admin Bereich konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen.
         * @param {Array<string>} [codeVerfahrenssteuerungen] optional Einschränkung nach {@link CodeVerfahrenssteuerung CodeVerfahrenssteuerung}
         * @param {Array<CodeVerfahrensschritt>} [codeVerfahrensschritte] optional Einschränkung nach {@link CodeVerfahrensschritt CodeVerfahrensschritt}
         * @param {Array<string>} [codeVerfahrensteilschritte] optional Einschränkung nach {@link CodeVerfahrensteilschritt CodeVerfahrensteilschritt}
         * @param {Array<string>} [dateiNamen] optional Einschränkung nach Dateinamen der Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageUebersicht: async (codeVerfahrenssteuerungen?: Array<string>, codeVerfahrensschritte?: Array<CodeVerfahrensschritt>, codeVerfahrensteilschritte?: Array<string>, dateiNamen?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vorlagen/uebersicht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVerfahrenssteuerungen) {
                localVarQueryParameter['codeVerfahrenssteuerungen'] = codeVerfahrenssteuerungen;
            }

            if (codeVerfahrensschritte) {
                localVarQueryParameter['codeVerfahrensschritte'] = codeVerfahrensschritte;
            }

            if (codeVerfahrensteilschritte) {
                localVarQueryParameter['codeVerfahrensteilschritte'] = codeVerfahrensteilschritte;
            }

            if (dateiNamen) {
                localVarQueryParameter['dateiNamen'] = dateiNamen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen für das übergebene Verfahren (planID).  Dieser Endpunkt ist für die Vorlagenübersicht in der Dokumentenbibliothek konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen für das übergebene Verfahren (planID).
         * @param {string} planID Angabe einer Verfahren planID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageUebersicht1: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVorlageUebersicht1', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/vorlagen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {File} vorlage 
         * @param {string} code 
         * @param {string} beschreibung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlage: async (vorlage: File, code: string, beschreibung: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vorlage' is not null or undefined
            assertParamExists('pruefeVorlage', 'vorlage', vorlage)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('pruefeVorlage', 'code', code)
            // verify required parameter 'beschreibung' is not null or undefined
            assertParamExists('pruefeVorlage', 'beschreibung', beschreibung)
            const localVarPath = `/vorlagen/pruefePlatzhalter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (beschreibung !== undefined) {
                localVarQueryParameter['beschreibung'] = beschreibung;
            }


            if (vorlage !== undefined) { 
                localVarFormParams.append('vorlage', vorlage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.
         * @param {string} codeMusterdokument der Code der zu prüfenden Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlage1: async (codeMusterdokument: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('pruefeVorlage1', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/vorlagen/{codeMusterdokument}/pruefePlatzhalter`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Prüft die vorhandenen Vorlagen auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die vorhandenen Vorlagen auf gültige Platzhalter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlagen: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vorlagen/pruefePlatzhalter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen. Es muss immer die komplette Liste uebergeben werden,  die Methode entspricht removeAll und addAll.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen.
         * @param {VorlageRest} vorlageRest REST-Objekt mit den neuen VorlageZuordnungen
         * @param {string} codeMusterdokument Das {@link de.xbauleitplanung.CodeMusterdokument de.xbauleitplanung.CodeMusterdokument} zu dem die Vorlage editiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVorlageVsVts: async (vorlageRest: VorlageRest, codeMusterdokument: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vorlageRest' is not null or undefined
            assertParamExists('setVorlageVsVts', 'vorlageRest', vorlageRest)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('setVorlageVsVts', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/vorlagen/{codeMusterdokument}`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vorlageRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Eine vorhandene Vorlage in der K1 ersetzen.  <p>  Wenn das Dokument (binär) bereits exakt gleich hochgeladen wurde, dann wird keine neue Version angelegt  (Vergleich mit Hash-Wert), ansonsten wird eine neue Version angelegt.  </p></br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine vorhandene Vorlage in der K1 ersetzen
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, skipValidation?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vorlage' is not null or undefined
            assertParamExists('update', 'vorlage', vorlage)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('update', 'codeMusterdokument', codeMusterdokument)
            // verify required parameter 'codeVerfahrensunterlagetyp' is not null or undefined
            assertParamExists('update', 'codeVerfahrensunterlagetyp', codeVerfahrensunterlagetyp)
            const localVarPath = `/vorlagen/{codeMusterdokument}/update`
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVerfahrensunterlagetyp !== undefined) {
                localVarQueryParameter['codeVerfahrensunterlagetyp'] = codeVerfahrensunterlagetyp;
            }

            if (skipValidation !== undefined) {
                localVarQueryParameter['skipValidation'] = skipValidation;
            }


            if (vorlage !== undefined) { 
                localVarFormParams.append('vorlage', vorlage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VorlageResourceApi - functional programming interface
 * @export
 */
export const VorlageResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VorlageResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Eine neue Vorlage in die K1 hochladen.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine neue Vorlage in die K1 hochladen.
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {string} beschreibung 
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, beschreibung: string, skipValidation?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlageRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, beschreibung, skipValidation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Mustervorlage (Metadaten inkl. Versionen und Binaries)</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Mustervorlage (Metadaten inkl.
         * @param {string} codeMusterdokument Musterdokumentcode zu dem die Vorlage komplett gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVorlage(codeMusterdokument: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVorlage(codeMusterdokument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.deleteVorlage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async determineNextFreeCodeMusterdokument(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.determineNextFreeCodeMusterdokument(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.determineNextFreeCodeMusterdokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Download einer Vorlage als Binary  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene records verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Download einer Vorlage als Binary  
         * @param {string} codeMusterdokument dokument.getcodeMusterdokument
         * @param {string} [version] version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVorlageDatei(codeMusterdokument: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVorlageDatei(codeMusterdokument, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.getVorlageDatei']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einer Vorlage (Dokument).</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Liefert die Metadaten zu einer Vorlage (Dokument).
         * @param {string} codeMusterdokument codeMusterdokument des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVorlageDetail(codeMusterdokument: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlageRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVorlageDetail(codeMusterdokument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.getVorlageDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen. Dieser Endpunkt ist für den Admin Bereich konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen.
         * @param {Array<string>} [codeVerfahrenssteuerungen] optional Einschränkung nach {@link CodeVerfahrenssteuerung CodeVerfahrenssteuerung}
         * @param {Array<CodeVerfahrensschritt>} [codeVerfahrensschritte] optional Einschränkung nach {@link CodeVerfahrensschritt CodeVerfahrensschritt}
         * @param {Array<string>} [codeVerfahrensteilschritte] optional Einschränkung nach {@link CodeVerfahrensteilschritt CodeVerfahrensteilschritt}
         * @param {Array<string>} [dateiNamen] optional Einschränkung nach Dateinamen der Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVorlageUebersicht(codeVerfahrenssteuerungen?: Array<string>, codeVerfahrensschritte?: Array<CodeVerfahrensschritt>, codeVerfahrensteilschritte?: Array<string>, dateiNamen?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VorlageRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVorlageUebersicht(codeVerfahrenssteuerungen, codeVerfahrensschritte, codeVerfahrensteilschritte, dateiNamen, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.getVorlageUebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen für das übergebene Verfahren (planID).  Dieser Endpunkt ist für die Vorlagenübersicht in der Dokumentenbibliothek konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen für das übergebene Verfahren (planID).
         * @param {string} planID Angabe einer Verfahren planID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVorlageUebersicht1(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenVorlageRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVorlageUebersicht1(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.getVorlageUebersicht1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {File} vorlage 
         * @param {string} code 
         * @param {string} beschreibung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pruefeVorlage(vorlage: File, code: string, beschreibung: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlagePruefungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pruefeVorlage(vorlage, code, beschreibung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.pruefeVorlage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.
         * @param {string} codeMusterdokument der Code der zu prüfenden Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pruefeVorlage1(codeMusterdokument: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlagePruefungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pruefeVorlage1(codeMusterdokument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.pruefeVorlage1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Prüft die vorhandenen Vorlagen auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die vorhandenen Vorlagen auf gültige Platzhalter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pruefeVorlagen(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VorlagePruefungRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pruefeVorlagen(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.pruefeVorlagen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen. Es muss immer die komplette Liste uebergeben werden,  die Methode entspricht removeAll und addAll.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen.
         * @param {VorlageRest} vorlageRest REST-Objekt mit den neuen VorlageZuordnungen
         * @param {string} codeMusterdokument Das {@link de.xbauleitplanung.CodeMusterdokument de.xbauleitplanung.CodeMusterdokument} zu dem die Vorlage editiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setVorlageVsVts(vorlageRest: VorlageRest, codeMusterdokument: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlageRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setVorlageVsVts(vorlageRest, codeMusterdokument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.setVorlageVsVts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Eine vorhandene Vorlage in der K1 ersetzen.  <p>  Wenn das Dokument (binär) bereits exakt gleich hochgeladen wurde, dann wird keine neue Version angelegt  (Vergleich mit Hash-Wert), ansonsten wird eine neue Version angelegt.  </p></br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine vorhandene Vorlage in der K1 ersetzen
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, skipValidation?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VorlageRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, skipValidation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VorlageResourceApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VorlageResourceApi - factory interface
 * @export
 */
export const VorlageResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VorlageResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Eine neue Vorlage in die K1 hochladen.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine neue Vorlage in die K1 hochladen.
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {string} beschreibung 
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, beschreibung: string, skipValidation?: boolean, options?: any): AxiosPromise<VorlageRest> {
            return localVarFp.create(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, beschreibung, skipValidation, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Mustervorlage (Metadaten inkl. Versionen und Binaries)</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Mustervorlage (Metadaten inkl.
         * @param {string} codeMusterdokument Musterdokumentcode zu dem die Vorlage komplett gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVorlage(codeMusterdokument: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVorlage(codeMusterdokument, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        determineNextFreeCodeMusterdokument(options?: any): AxiosPromise<string> {
            return localVarFp.determineNextFreeCodeMusterdokument(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Download einer Vorlage als Binary  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene records verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Download einer Vorlage als Binary  
         * @param {string} codeMusterdokument dokument.getcodeMusterdokument
         * @param {string} [version] version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageDatei(codeMusterdokument: string, version?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getVorlageDatei(codeMusterdokument, version, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einer Vorlage (Dokument).</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Liefert die Metadaten zu einer Vorlage (Dokument).
         * @param {string} codeMusterdokument codeMusterdokument des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageDetail(codeMusterdokument: string, options?: any): AxiosPromise<VorlageRest> {
            return localVarFp.getVorlageDetail(codeMusterdokument, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen. Dieser Endpunkt ist für den Admin Bereich konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen.
         * @param {Array<string>} [codeVerfahrenssteuerungen] optional Einschränkung nach {@link CodeVerfahrenssteuerung CodeVerfahrenssteuerung}
         * @param {Array<CodeVerfahrensschritt>} [codeVerfahrensschritte] optional Einschränkung nach {@link CodeVerfahrensschritt CodeVerfahrensschritt}
         * @param {Array<string>} [codeVerfahrensteilschritte] optional Einschränkung nach {@link CodeVerfahrensteilschritt CodeVerfahrensteilschritt}
         * @param {Array<string>} [dateiNamen] optional Einschränkung nach Dateinamen der Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageUebersicht(codeVerfahrenssteuerungen?: Array<string>, codeVerfahrensschritte?: Array<CodeVerfahrensschritt>, codeVerfahrensteilschritte?: Array<string>, dateiNamen?: Array<string>, options?: any): AxiosPromise<Array<VorlageRest>> {
            return localVarFp.getVorlageUebersicht(codeVerfahrenssteuerungen, codeVerfahrensschritte, codeVerfahrensteilschritte, dateiNamen, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Vorlagen für das übergebene Verfahren (planID).  Dieser Endpunkt ist für die Vorlagenübersicht in der Dokumentenbibliothek konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Auflistung aller Vorlagen für das übergebene Verfahren (planID).
         * @param {string} planID Angabe einer Verfahren planID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorlageUebersicht1(planID: string, options?: any): AxiosPromise<Array<VerfahrenVorlageRest>> {
            return localVarFp.getVorlageUebersicht1(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {File} vorlage 
         * @param {string} code 
         * @param {string} beschreibung 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlage(vorlage: File, code: string, beschreibung: string, options?: any): AxiosPromise<VorlagePruefungRest> {
            return localVarFp.pruefeVorlage(vorlage, code, beschreibung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.
         * @param {string} codeMusterdokument der Code der zu prüfenden Vorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlage1(codeMusterdokument: string, options?: any): AxiosPromise<VorlagePruefungRest> {
            return localVarFp.pruefeVorlage1(codeMusterdokument, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Prüft die vorhandenen Vorlagen auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @summary Prüft die vorhandenen Vorlagen auf gültige Platzhalter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pruefeVorlagen(options?: any): AxiosPromise<Array<VorlagePruefungRest>> {
            return localVarFp.pruefeVorlagen(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen. Es muss immer die komplette Liste uebergeben werden,  die Methode entspricht removeAll und addAll.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen.
         * @param {VorlageRest} vorlageRest REST-Objekt mit den neuen VorlageZuordnungen
         * @param {string} codeMusterdokument Das {@link de.xbauleitplanung.CodeMusterdokument de.xbauleitplanung.CodeMusterdokument} zu dem die Vorlage editiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVorlageVsVts(vorlageRest: VorlageRest, codeMusterdokument: string, options?: any): AxiosPromise<VorlageRest> {
            return localVarFp.setVorlageVsVts(vorlageRest, codeMusterdokument, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Eine vorhandene Vorlage in der K1 ersetzen.  <p>  Wenn das Dokument (binär) bereits exakt gleich hochgeladen wurde, dann wird keine neue Version angelegt  (Vergleich mit Hash-Wert), ansonsten wird eine neue Version angelegt.  </p></br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @summary Eine vorhandene Vorlage in der K1 ersetzen
         * @param {File} vorlage 
         * @param {string} codeMusterdokument codeMusterdokument
         * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
         * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, skipValidation?: boolean, options?: any): AxiosPromise<VorlageRest> {
            return localVarFp.update(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, skipValidation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VorlageResourceApi - object-oriented interface
 * @export
 * @class VorlageResourceApi
 * @extends {BaseAPI}
 */
export class VorlageResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Eine neue Vorlage in die K1 hochladen.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @summary Eine neue Vorlage in die K1 hochladen.
     * @param {File} vorlage 
     * @param {string} codeMusterdokument codeMusterdokument
     * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
     * @param {string} beschreibung 
     * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public create(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, beschreibung: string, skipValidation?: boolean, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).create(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, beschreibung, skipValidation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Löscht eine Mustervorlage (Metadaten inkl. Versionen und Binaries)</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Löscht eine Mustervorlage (Metadaten inkl.
     * @param {string} codeMusterdokument Musterdokumentcode zu dem die Vorlage komplett gelöscht werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public deleteVorlage(codeMusterdokument: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).deleteVorlage(codeMusterdokument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public determineNextFreeCodeMusterdokument(options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).determineNextFreeCodeMusterdokument(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Download einer Vorlage als Binary  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene records verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Download einer Vorlage als Binary  
     * @param {string} codeMusterdokument dokument.getcodeMusterdokument
     * @param {string} [version] version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public getVorlageDatei(codeMusterdokument: string, version?: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).getVorlageDatei(codeMusterdokument, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Metadaten zu einer Vorlage (Dokument).</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Liefert die Metadaten zu einer Vorlage (Dokument).
     * @param {string} codeMusterdokument codeMusterdokument des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public getVorlageDetail(codeMusterdokument: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).getVorlageDetail(codeMusterdokument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Auflistung aller Vorlagen. Dieser Endpunkt ist für den Admin Bereich konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Auflistung aller Vorlagen.
     * @param {Array<string>} [codeVerfahrenssteuerungen] optional Einschränkung nach {@link CodeVerfahrenssteuerung CodeVerfahrenssteuerung}
     * @param {Array<CodeVerfahrensschritt>} [codeVerfahrensschritte] optional Einschränkung nach {@link CodeVerfahrensschritt CodeVerfahrensschritt}
     * @param {Array<string>} [codeVerfahrensteilschritte] optional Einschränkung nach {@link CodeVerfahrensteilschritt CodeVerfahrensteilschritt}
     * @param {Array<string>} [dateiNamen] optional Einschränkung nach Dateinamen der Vorlage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public getVorlageUebersicht(codeVerfahrenssteuerungen?: Array<string>, codeVerfahrensschritte?: Array<CodeVerfahrensschritt>, codeVerfahrensteilschritte?: Array<string>, dateiNamen?: Array<string>, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).getVorlageUebersicht(codeVerfahrenssteuerungen, codeVerfahrensschritte, codeVerfahrensteilschritte, dateiNamen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Auflistung aller Vorlagen für das übergebene Verfahren (planID).  Dieser Endpunkt ist für die Vorlagenübersicht in der Dokumentenbibliothek konzipiert.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Auflistung aller Vorlagen für das übergebene Verfahren (planID).
     * @param {string} planID Angabe einer Verfahren planID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public getVorlageUebersicht1(planID: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).getVorlageUebersicht1(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @param {File} vorlage 
     * @param {string} code 
     * @param {string} beschreibung 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public pruefeVorlage(vorlage: File, code: string, beschreibung: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).pruefeVorlage(vorlage, code, beschreibung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Prüft die zum <code>codeMusterdokument</code> gehörende Vorlage auf gültige Platzhalter.
     * @param {string} codeMusterdokument der Code der zu prüfenden Vorlage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public pruefeVorlage1(codeMusterdokument: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).pruefeVorlage1(codeMusterdokument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Prüft die vorhandenen Vorlagen auf gültige Platzhalter.</br></br><b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @summary Prüft die vorhandenen Vorlagen auf gültige Platzhalter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public pruefeVorlagen(options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).pruefeVorlagen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen. Es muss immer die komplette Liste uebergeben werden,  die Methode entspricht removeAll und addAll.</br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @summary Vorlage bestimmten Verfahrens- und Verfahrensteilschritten zuweisen.
     * @param {VorlageRest} vorlageRest REST-Objekt mit den neuen VorlageZuordnungen
     * @param {string} codeMusterdokument Das {@link de.xbauleitplanung.CodeMusterdokument de.xbauleitplanung.CodeMusterdokument} zu dem die Vorlage editiert werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public setVorlageVsVts(vorlageRest: VorlageRest, codeMusterdokument: string, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).setVorlageVsVts(vorlageRest, codeMusterdokument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Eine vorhandene Vorlage in der K1 ersetzen.  <p>  Wenn das Dokument (binär) bereits exakt gleich hochgeladen wurde, dann wird keine neue Version angelegt  (Vergleich mit Hash-Wert), ansonsten wird eine neue Version angelegt.  </p></br></br><b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @summary Eine vorhandene Vorlage in der K1 ersetzen
     * @param {File} vorlage 
     * @param {string} codeMusterdokument codeMusterdokument
     * @param {string} codeVerfahrensunterlagetyp Verfahrensunterlagetyp Code
     * @param {boolean} [skipValidation] wenn vorhanden und wenn &lt;code&gt;true&lt;/code&gt; wird die Platzhalterprüfung übersprungen (ADO-2767)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VorlageResourceApi
     */
    public update(vorlage: File, codeMusterdokument: string, codeVerfahrensunterlagetyp: string, skipValidation?: boolean, options?: RawAxiosRequestConfig) {
        return VorlageResourceApiFp(this.configuration).update(vorlage, codeMusterdokument, codeVerfahrensunterlagetyp, skipValidation, options).then((request) => request(this.axios, this.basePath));
    }
}

