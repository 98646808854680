<script>
  export default {
    name: "AppLoadingSpinner",
    props: {
      numberOfBars: {
        type: Number,
        default: 24,
      },
      //white or black
      color: {
        type: String,
        default: "white",
      },
      size: {
        type: Number,
        default: 80,
      },
    },
  };
</script>

<template>
  <div
    class="dpc-loading-spinner"
    :class="'dpc-loading-spinner--' + color"
    :style="`--size: ${size}px;`"
    role="status"
    aria-hidden="true"
  >
    <div
      v-for="bar in numberOfBars"
      :key="'bar-' + bar"
      class="dpc-loading-spinner__bar"
      :style="`--rotation: ${bar * (360 / numberOfBars)}deg;
       --delay: ${bar * (1 / numberOfBars)}s;
       --width: ${100 / numberOfBars / 2}%`"
    ></div>
  </div>
</template>

<style lang="scss">
  .dpc-loading-spinner {
    position: relative;
    display: inline-block;
    margin-left: 50%;
    margin-right: 50%;
    padding: 10px;
    width: var(--size);
    height: var(--size);

    &__bar {
      height: 24%;
      position: absolute;
      left: 49%;
      top: 43%;
      opacity: 0;
      border-radius: 50px;
      box-shadow: 0 0 3px rgba(0 0 0 / 20%);
      animation: fade 1s linear infinite;
      transform: rotate(var(--rotation)) translate(0, -155%);
      animation-delay: var(--delay);
      width: var(--width);
    }

    &--white .dpc-loading-spinner__bar {
      background-color: var(--dps-color-white);
    }

    &--black .dpc-loading-spinner__bar {
      background-color: var(--dps-color-black);
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
</style>
