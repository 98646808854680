/*
                              diplanung.de
    _______________________________|______________________________
   |                               |                             |
DiPlanCockpit                DiPlanBeteiligung                DiPlanPortal
=============                =================                ============
cockpit.test.diplanung.de    beteiligung.test.diplanung.de    portal.test.diplanung.de
cockpit.stage.diplanung.de   beteiligung.stage.diplanung.de   portal.stage.diplanung.de


                              diplanung.de
    _______________________________|______________________________
   |                               |                             |
DiPlanCockpit               DiPlanBeteiligung               DiPlanPortal
=============               =================               ============
hh.cockpit.diplanung.de     hh.beteiligung.diplanung.de     portal.diplanung.de
by.cockpit.diplanung.de     by.beteiligung.diplanung.de
be.cockpit.diplanung.de     be.beteiligung.diplanung.de
...

*/

interface LocationEnvironmentUrls {
  beteiligung: string;
  portal: string;
}

export function getEnvironmentLinks(host = window.location.host): LocationEnvironmentUrls {
  const isLocal = !host.includes("cockpit");
  const urlSuffix = host.split("cockpit").pop();
  let prefixString = getUrlPrefix(host);

  prefixString = prefixString ? prefixString + "." : "";

  return {
    beteiligung: !isLocal ? prefixString + "beteiligung" + urlSuffix : host + "/beteiligung",
    portal: !isLocal ? "portal" + urlSuffix : host + "/portal",
  };
}

export function getUrlPrefix(hostname: string = window.location.hostname): string {
  const urlParts = hostname.split(".");
  const urlPrefix = urlParts?.[0];

  if (
    urlParts.length < 3 ||
    urlPrefix === undefined ||
    urlPrefix === "cockpit" ||
    urlPrefix === "default" ||
    urlPrefix === "init"
  ) {
    return "";
  }

  return urlPrefix;
}
