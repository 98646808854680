<script>
  import { DpsButton } from "diplanung-style";

  import AppModal from "@/components/AppModal.vue";

  export default {
    name: "AppModalConfirm",
    components: {
      DpsButton,
    },
    extends: AppModal,
    props: {
      hasCancelButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      headline: {
        type: String,
        required: true,
      },
      infotext: {
        type: String,
        required: true,
      },
      isDelete: {
        type: Boolean,
        required: false,
        default: false,
      },
      callbackConfirmed: {
        type: Function,
        required: true,
      },
      callbackConfirmedArguments: {
        type: Array,
        required: false,
        default() {
          return [];
        },
      },
      callbackConfirmedText: {
        type: String,
        required: false,
        default: "Ja",
      },
      callbackDenied: {
        type: Function,
        required: false,
        default: undefined,
      },
      callbackDeniedArguments: {
        type: Array,
        required: false,
        default() {
          return [];
        },
      },
      callbackDeniedText: {
        type: String,
        required: false,
        default: "Nein",
      },
      cancelButtonCallback: {
        type: Function,
        required: false,
        default: undefined,
      },
      cancelButtonLabel: {
        type: String,
        required: false,
        default: "Abbrechen",
      },
    },
    emits: ["close-modal"],
    data() {
      return {
        refName: "modalConfirm",
        showModal: true,
      };
    },
    methods: {
      handleButtonClick(type) {
        this.$emit("close-modal");

        if (type === "cancel" && this.cancelButtonCallback) {
          this.cancelButtonCallback();
        } else if (type === "deny" && this.callbackDenied) {
          this.callbackDenied(...this.callbackDeniedArguments);
        } else if (type === "confirm") {
          this.callbackConfirmed(...this.callbackConfirmedArguments);
        }
      },
    },
  };
</script>

<template>
  <div
    :ref="refName"
    class="dpc-modal-confirm modal modal-info dpc-fade"
    :class="isDelete ? 'modal--delete' : 'modal--warning'"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- header -->
        <div v-if="headline" class="modal-header">
          <span
            class="dps-icon"
            :class="isDelete ? 'dps-icon--delete delete' : 'dps-icon--warning warning'"
          ></span>
          <span class="modal-hl" :class="isDelete ? 'delete' : 'warning'">{{ headline }}</span>
        </div>

        <!-- body -->
        <div class="modal-body">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-if="infotext" class="modal-info-text text-break" v-html="infotext"></p>
        </div>

        <!-- footer -->
        <div class="modal-footer">
          <div
            class="dps-button-group"
            role="group"
            aria-label="Buttons für Abbrechen und Übernehmen"
          >
            <DpsButton
              v-if="hasCancelButton"
              :title="cancelButtonLabel"
              variant="secondary"
              size="sm"
              @click="handleButtonClick('cancel')"
              @keydown.space="handleButtonClick('cancel')"
            >
              {{ cancelButtonLabel }}
            </DpsButton>
            <DpsButton
              :title="callbackDeniedText"
              variant="secondary"
              size="sm"
              @click="handleButtonClick('deny')"
              @keydown.space="handleButtonClick('deny')"
            >
              {{ callbackDeniedText }}
            </DpsButton>
            <DpsButton
              :title="callbackConfirmedText"
              size="sm"
              @click="handleButtonClick('confirm')"
              @keydown.space="handleButtonClick('confirm')"
            >
              {{ callbackConfirmedText }}
            </DpsButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
