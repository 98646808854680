import type { AxiosInstance } from "axios";

import {
  ActionItemResourceApiFactory,
  AufgabeResourceApiFactory,
  BlacklistResourceApiFactory,
  CodelistResourceApiFactory,
  Configuration,
  DokumentResourceApiFactory,
  EmailMsgResourceApiFactory,
  GenericCodelistResourceApiFactory,
  KontaktResourceApiFactory,
  MetadatenExportResourceApiFactory,
  MonitoringRessourceApiFactory,
  NutzerResourceApiFactory,
  PlannameValidatorkonfigurationResourceApiFactory,
  ProtokollResourceApiFactory,
  RechtResourceApiFactory,
  RechtszitatResourceApiFactory,
  RolleResourceApiFactory,
  SystemParameterResourceApiFactory,
  TemplateResourceApiFactory,
  TerminResourceApiFactory,
  TransferMessageResourceApiFactory,
  UnterAufgabeResourceApiFactory,
  UnterverfahrensteilschrittResourceApiFactory,
  VerfahrenEinstellungenRessourceApiFactory,
  VerfahrenResourceApiFactory,
  VerfahrensKonfigurationRessourceApiFactory,
  VerfahrensteilschrittResourceApiFactory,
  VersionResourceApiFactory,
  VertragsdokumentResourceApiFactory,
  VorlageResourceApiFactory,
  WhitelistResourceApiFactory,
  XplanManagerResourceApiFactory,
  XPlanverfahrenImportResourceApiFactory,
} from "./open-api/index.ts";

const basePath = "/api/cockpit" as const;
const configuration = new Configuration({
  basePath: basePath,
});

export class OpenAPIFactory {
  constructor(private readonly axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }
  codelistResourceApiFactory = () =>
    CodelistResourceApiFactory(configuration, basePath, this.axiosInstance);
  dokumentResourceApiFactory = () =>
    DokumentResourceApiFactory(configuration, basePath, this.axiosInstance);
  nutzerResourceApiFactory = () =>
    NutzerResourceApiFactory(configuration, basePath, this.axiosInstance);
  plannameValidatorkonfigurationResourceApiFactory = () =>
    PlannameValidatorkonfigurationResourceApiFactory(configuration, basePath, this.axiosInstance);
  protokollResourceApiFactory = () =>
    ProtokollResourceApiFactory(configuration, basePath, this.axiosInstance);
  rechtResourceApiFactory = () =>
    RechtResourceApiFactory(configuration, basePath, this.axiosInstance);
  rolleResourceApiFactory = () =>
    RolleResourceApiFactory(configuration, basePath, this.axiosInstance);
  systemParameterResourceApiFactory = () =>
    SystemParameterResourceApiFactory(configuration, basePath, this.axiosInstance);
  rechtszitatResourceApiFactory = () =>
    RechtszitatResourceApiFactory(configuration, basePath, this.axiosInstance);
  transferMessageResourceApiFactory = () =>
    TransferMessageResourceApiFactory(configuration, basePath, this.axiosInstance);
  verfahrensKonfigurationRessourceApiFactory = () =>
    VerfahrensKonfigurationRessourceApiFactory(configuration, basePath, this.axiosInstance);
  verfahrenResourceApiFactory = () =>
    VerfahrenResourceApiFactory(configuration, basePath, this.axiosInstance);
  versionResourceApiFactory = () =>
    VersionResourceApiFactory(configuration, basePath, this.axiosInstance);
  VerfahrenEinstellungenRessourceApiFactory = () =>
    VerfahrenEinstellungenRessourceApiFactory(configuration, basePath, this.axiosInstance);
  vertragsdokumentResourceApiFactory = () =>
    VertragsdokumentResourceApiFactory(configuration, basePath, this.axiosInstance);
  vorlageResourceApiFactory = () =>
    VorlageResourceApiFactory(configuration, basePath, this.axiosInstance);
  xplanManagerResourceApiFactory = () =>
    XplanManagerResourceApiFactory(configuration, basePath, this.axiosInstance);
  xPlanverfahrenImportResourceApiFactory = () =>
    XPlanverfahrenImportResourceApiFactory(configuration, basePath, this.axiosInstance);
  genericCodelistResourceApiFactory = () =>
    GenericCodelistResourceApiFactory(configuration, basePath, this.axiosInstance);
  blacklistResourceApiFactory = () =>
    BlacklistResourceApiFactory(configuration, basePath, this.axiosInstance);
  whitelistResourceApiFactory = () =>
    WhitelistResourceApiFactory(configuration, basePath, this.axiosInstance);
  verfahrensteilschrittResourceApiFactory = () =>
    VerfahrensteilschrittResourceApiFactory(configuration, basePath, this.axiosInstance);
  aufgabeResourceApiFactory = () =>
    AufgabeResourceApiFactory(configuration, basePath, this.axiosInstance);
  unterverfahrensteilschrittResourceApiFactory = () =>
    UnterverfahrensteilschrittResourceApiFactory(configuration, basePath, this.axiosInstance);
  unterAufgabeResourceApiFactory = () =>
    UnterAufgabeResourceApiFactory(configuration, basePath, this.axiosInstance);
  actionItemResourceApiFactory = () =>
    ActionItemResourceApiFactory(configuration, basePath, this.axiosInstance);
  emailMsgResourceApiFactory = () =>
    EmailMsgResourceApiFactory(configuration, basePath, this.axiosInstance);
  terminResourceApiFactory = () =>
    TerminResourceApiFactory(configuration, basePath, this.axiosInstance);
  monitoringResourceApiFactory = () =>
    MonitoringRessourceApiFactory(configuration, basePath, this.axiosInstance);
  kontaktResourceApiFactory = () =>
    KontaktResourceApiFactory(configuration, basePath, this.axiosInstance);
  templateResourceApiFactory = () =>
    TemplateResourceApiFactory(configuration, basePath, this.axiosInstance);
  metadatenExportResourceApiFactory = () =>
    MetadatenExportResourceApiFactory(configuration, basePath, this.axiosInstance);
}
