/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.135.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { VertragsErgaenzungsdokumentEditRest } from '../models';
// @ts-ignore
import { VertragsErgaenzungsdokumentNeuRest } from '../models';
// @ts-ignore
import { VertragsErgaenzungsdokumentRest } from '../models';
// @ts-ignore
import { VertragsdokumentEditRest } from '../models';
// @ts-ignore
import { VertragsdokumentNeuRest } from '../models';
// @ts-ignore
import { VertragsdokumentRest } from '../models';
/**
 * VertragsdokumentResourceApi - axios parameter creator
 * @export
 */
export const VertragsdokumentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertragsdokument: async (vertrag: VertragsdokumentNeuRest, file: File, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vertrag' is not null or undefined
            assertParamExists('createVertragsdokument', 'vertrag', vertrag)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createVertragsdokument', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createVertragsdokument', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (vertrag !== undefined) { 
                localVarFormParams.append('vertrag', new Blob([JSON.stringify(vertrag)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertragsdokumentErgaenzung: async (vertrag: VertragsErgaenzungsdokumentNeuRest, file: File, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vertrag' is not null or undefined
            assertParamExists('createVertragsdokumentErgaenzung', 'vertrag', vertrag)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createVertragsdokumentErgaenzung', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createVertragsdokumentErgaenzung', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('createVertragsdokumentErgaenzung', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument/{fileID}/ergaenzung`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (vertrag !== undefined) { 
                localVarFormParams.append('vertrag', new Blob([JSON.stringify(vertrag)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertragsdokumentErgaenzungen: async (planID: string, vertragsdokumentFileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVertragsdokumentErgaenzungen', 'planID', planID)
            // verify required parameter 'vertragsdokumentFileID' is not null or undefined
            assertParamExists('getVertragsdokumentErgaenzungen', 'vertragsdokumentFileID', vertragsdokumentFileID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument/{vertragsdokumentFileID}/ergaenzung`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"vertragsdokumentFileID"}}`, encodeURIComponent(String(vertragsdokumentFileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertragsdokumente: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVertragsdokumente', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertragsdokument: async (vertrag: VertragsdokumentEditRest, planID: string, fileID: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vertrag' is not null or undefined
            assertParamExists('updateVertragsdokument', 'vertrag', vertrag)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateVertragsdokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('updateVertragsdokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument/{fileID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (vertrag !== undefined) { 
                localVarFormParams.append('vertrag', new Blob([JSON.stringify(vertrag)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertragsdokumentErgaenzung: async (vertrag: VertragsErgaenzungsdokumentEditRest, planID: string, vertragsdokumentFileID: string, fileID: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vertrag' is not null or undefined
            assertParamExists('updateVertragsdokumentErgaenzung', 'vertrag', vertrag)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateVertragsdokumentErgaenzung', 'planID', planID)
            // verify required parameter 'vertragsdokumentFileID' is not null or undefined
            assertParamExists('updateVertragsdokumentErgaenzung', 'vertragsdokumentFileID', vertragsdokumentFileID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('updateVertragsdokumentErgaenzung', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/vertragsdokument/{vertragsdokumentFileID}/ergaenzung/{fileID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"vertragsdokumentFileID"}}`, encodeURIComponent(String(vertragsdokumentFileID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (vertrag !== undefined) { 
                localVarFormParams.append('vertrag', new Blob([JSON.stringify(vertrag)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VertragsdokumentResourceApi - functional programming interface
 * @export
 */
export const VertragsdokumentResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VertragsdokumentResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVertragsdokument(vertrag: VertragsdokumentNeuRest, file: File, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragsdokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVertragsdokument(vertrag, file, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.createVertragsdokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentNeuRest, file: File, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragsErgaenzungsdokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVertragsdokumentErgaenzung(vertrag, file, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.createVertragsdokumentErgaenzung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVertragsdokumentErgaenzungen(planID: string, vertragsdokumentFileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VertragsErgaenzungsdokumentRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVertragsdokumentErgaenzungen(planID, vertragsdokumentFileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.getVertragsdokumentErgaenzungen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVertragsdokumente(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VertragsdokumentRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVertragsdokumente(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.getVertragsdokumente']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVertragsdokument(vertrag: VertragsdokumentEditRest, planID: string, fileID: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragsdokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVertragsdokument(vertrag, planID, fileID, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.updateVertragsdokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentEditRest, planID: string, vertragsdokumentFileID: string, fileID: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragsErgaenzungsdokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVertragsdokumentErgaenzung(vertrag, planID, vertragsdokumentFileID, fileID, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VertragsdokumentResourceApi.updateVertragsdokumentErgaenzung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VertragsdokumentResourceApi - factory interface
 * @export
 */
export const VertragsdokumentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VertragsdokumentResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertragsdokument(vertrag: VertragsdokumentNeuRest, file: File, planID: string, options?: any): AxiosPromise<VertragsdokumentRest> {
            return localVarFp.createVertragsdokument(vertrag, file, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentNeuRest, file: File, planID: string, fileID: string, options?: any): AxiosPromise<VertragsErgaenzungsdokumentRest> {
            return localVarFp.createVertragsdokumentErgaenzung(vertrag, file, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertragsdokumentErgaenzungen(planID: string, vertragsdokumentFileID: string, options?: any): AxiosPromise<Array<VertragsErgaenzungsdokumentRest>> {
            return localVarFp.getVertragsdokumentErgaenzungen(planID, vertragsdokumentFileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertragsdokumente(planID: string, options?: any): AxiosPromise<Array<VertragsdokumentRest>> {
            return localVarFp.getVertragsdokumente(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertragsdokument(vertrag: VertragsdokumentEditRest, planID: string, fileID: string, file?: File, options?: any): AxiosPromise<VertragsdokumentRest> {
            return localVarFp.updateVertragsdokument(vertrag, planID, fileID, file, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @param {VertragsErgaenzungsdokumentEditRest} vertrag 
         * @param {string} planID 
         * @param {string} vertragsdokumentFileID 
         * @param {string} fileID 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentEditRest, planID: string, vertragsdokumentFileID: string, fileID: string, file?: File, options?: any): AxiosPromise<VertragsErgaenzungsdokumentRest> {
            return localVarFp.updateVertragsdokumentErgaenzung(vertrag, planID, vertragsdokumentFileID, fileID, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VertragsdokumentResourceApi - object-oriented interface
 * @export
 * @class VertragsdokumentResourceApi
 * @extends {BaseAPI}
 */
export class VertragsdokumentResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @param {VertragsdokumentNeuRest} vertrag 
     * @param {File} file 
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public createVertragsdokument(vertrag: VertragsdokumentNeuRest, file: File, planID: string, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).createVertragsdokument(vertrag, file, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @param {VertragsErgaenzungsdokumentNeuRest} vertrag 
     * @param {File} file 
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public createVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentNeuRest, file: File, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).createVertragsdokumentErgaenzung(vertrag, file, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
     * @param {string} planID 
     * @param {string} vertragsdokumentFileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public getVertragsdokumentErgaenzungen(planID: string, vertragsdokumentFileID: string, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).getVertragsdokumentErgaenzungen(planID, vertragsdokumentFileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERTRAG_READ or EIGENE_VERTRAG_READ or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public getVertragsdokumente(planID: string, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).getVertragsdokumente(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @param {VertragsdokumentEditRest} vertrag 
     * @param {string} planID 
     * @param {string} fileID 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public updateVertragsdokument(vertrag: VertragsdokumentEditRest, planID: string, fileID: string, file?: File, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).updateVertragsdokument(vertrag, planID, fileID, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @param {VertragsErgaenzungsdokumentEditRest} vertrag 
     * @param {string} planID 
     * @param {string} vertragsdokumentFileID 
     * @param {string} fileID 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragsdokumentResourceApi
     */
    public updateVertragsdokumentErgaenzung(vertrag: VertragsErgaenzungsdokumentEditRest, planID: string, vertragsdokumentFileID: string, fileID: string, file?: File, options?: RawAxiosRequestConfig) {
        return VertragsdokumentResourceApiFp(this.configuration).updateVertragsdokumentErgaenzung(vertrag, planID, vertragsdokumentFileID, fileID, file, options).then((request) => request(this.axios, this.basePath));
    }
}

